import axios from "axios";
import { apexApiClient } from "./api_client";
import { extractAPIKey, fetchDealFieldsFromApp, readDealId, storeDealId } from "./methods";

export const PipedriveApi = "https://financeninja.pipedrive.com/api/v1";
export const PipedriveAuthToken = "0bf95b5f70cd664604b00fd3b334f24f347de537";
const creditScoreApi = `https://clienttest.dnb.com.au/CCBB2BService/creditcheck.asmx`;
export const downloadPipedriveFields = async () => {
  let fields = await getDealFields();
  let nameCangedFields = fields.map(field=>{
    const {name} = field;
    let nameChange = name.replaceAll(" ","_").replaceAll("/","_");
    return {
      ...field,
      name:nameChange
    };
  });
  return nameCangedFields;
};
export const checkCreditScore = async (data) => {
  const headers = {
    "Content-Type": "text/xml",
  };
  try {
    let response = await axios.post(
      `/CCBB2BService/creditcheck.asmx`,
      data,
      headers
    );
  } catch (error) {}
};
export const fetchScoreFromApi =(data)=>apexApiClient({
  method: "POST",
  url: "/credit/checkCredit",
  data:data
}).then(res=>res.data);


// async (data) => {
//   let dataToSend = {
//     ...data,
//     optMailing: false,
//     isCurrentAddress: true,
//     accountTypeCode: "011",
//     creditObligationCode: "005",
//   };
//   let response = await axios.post(
//     `https://apex-byd-finance.herokuapp.com/api/checkCredit`,
//     dataToSend
//   );

//   return response.data.score;
// };

export const updateADeal = async (body) => {
  let dealId = readDealId();
  let response = await axios.put(
    `${PipedriveApi}/deals/${dealId}?api_token=${PipedriveAuthToken}`,
    body
  );
};

export const uploadBankStatement = async (file) => {
  let form = new FormData();
  form.append("fileToUpload", file);
  let response = await axios.post(`/api/v1/upload`, form, {
    headers: {
      "X-API-KEY": "X6HH6QXTXZSBGWBKGMAJA96JJIQYFXDU7FVM8285",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
export const getDealFields = ()=>apexApiClient({
  method: "GET",
  url: "/pipedrive/getDealFields"
}).then(res=>res.data);
export const placeLookup = (place)=>apexApiClient({
  method:"GET",
  url:encodeURI(`/place/lookup?key=${place}`)
}).then(res=>res.data);
export const createDeal = (fields)=>apexApiClient({
  method: "POST",
  url:`/fields/addFields`,
  data: fields
}).then(res=>res.data);
export const savePersonalInfo = (fields,dealId)=>apexApiClient({
  method: "PATCH",
  url:`/fields/savePersonalInfo/${dealId}`,
  data: fields
}).then(res=>res.data);
export const saveJointPersonalInfo = (fields,dealId)=>apexApiClient({
  method: "PATCH",
  url:`/fields/joint/savePersonalInfo/${dealId}`,
  data: fields
}).then(res=>res.data);
export const saveAssetAndIncome = (fields,dealId)=>apexApiClient({
  method:"PATCH",
  url: `/fields/saveAssetIncome/${dealId}`,
  data:fields
}).then(res=>res.data);
export const saveLiabilityExpense = (fields,dealId)=>apexApiClient({
  method:"PATCH",
  url: `/fields/saveLiabilityExpense/${dealId}`,
  data:fields

}).then(res=>res.data);
export const sendEmail = (body)=>apexApiClient({
  method: "POST",
  url:`/mail/sendEmail`,
  data:body
}).then(res=>res.data);
export const saveAllToLixi = (dealId)=>apexApiClient({
  method: "POST",
  url:`/fields/saveFieldsToLixi/${dealId}`
}).then(res=>res.data);

export const initializeApplication = (body)=>apexApiClient({
  method: "POST",
  url: `/application/initialize`,
  data:body
}).then(res=>res.data);

export const saveApplication = (body)=>apexApiClient({
  method: "POST",
  url: `/application/save`,
  data: body
}).then(res=>res.data);

export const updateApplicationStage = (body)=>apexApiClient({
  method: "PATCH",
  url: `/application/updateStage/${readDealId()}`,
  data:body
}).then(res=>res.data);
export const saveFile = (fields)=>apexApiClient({
  method:"PATCH",
  url: `/fields/addFile/${readDealId()}`,
  data:fields,
  headers:{
    'Content-Type': 'multipart/form-data'
  }

}).then(res=>res.data);
// export const uploadFileIllion = ()=>apexApiClient({
//   method: "POST",
//   url:`/fields/saveFieldsToLixi/${dealId}`
// }).then(res=>res.data);