import React from "react";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { AppContext } from "../../app_provider";
import Card from "../../common/components/other/card";
import {
  extractAPIKey,
  fetchDealFieldsFromApp,
  readMarriageStatus,
  readMortageStatus,
  readDealId,
} from "../../utilities/methods";
import ButtonPanel from "../../common/components/other/button_panel";
import "./income_asset.css";
import { saveAssetAndIncome, updateADeal } from "../../utilities/api";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import {
  dropdownList,
  employementFields,
  incomeFields,
  payDecreased,
  previousEmployementFields,
  selfEmployed,
  timeWithCurrentEmployer,
  timeWithPreviousEmployer,
  valueFields,
} from "./const";
import { fetchLabelText, renderOptions } from "./methods";
const IncomeAsset = () => {
  /// States

  const [formFields, setFormFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isMarried, setIsMarried] = useState(false);
  const [isMortaged, setIsMortaged] = useState(false);
  const { setProgress, updateLoading } = useContext(AppContext);
  const [isJointApplication,setIsJointApplication] = useState(false);
  const navigate = useNavigate();
  const excludeList = useRef([]);
  
  /// UseEffect
  useEffect(() => {
    preRunTasks();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const preRunTasks = async () => {
    setProgress(60);
    console.log(isMarried);
    let jointApplicantInfo = localStorage.getItem("JOINT_APPLICANT_INFO");
    const _isJointApplication = jointApplicantInfo!=null&&jointApplicantInfo!="";
    setIsJointApplication(_isJointApplication);
    let formFieldsFromLocal = fetchDealFieldsFromApp();
    let dealId = readDealId();
    if (!formFieldsFromLocal || !dealId) {
      navigate("/");
    }
    let married = readMarriageStatus();
    let mortaged = readMortageStatus();
    setIsMarried(married);
    setIsMortaged(mortaged);
    setFormFields(formFieldsFromLocal);
    if (isMarried) {
      excludeList.current = ["Parents_Income_Per_Month"];
    }
    updateLoading(false);
  };
  const onInputChange = (e) => {
    const { name, value } = e.target;

    setFormErrors({ ...formErrors, [name]: false });
    console.log(value.split(",").join(""));
    setFormData({
      ...formData,
      [name]: !isNaN(value.split(",").join(""))
        ? numberWithCommas(value.split(",").join(""))
        : value,
    });
  };
  const onInputChangeSimple = (e) => {
    const { name, value } = e.target;

    setFormErrors({ ...formErrors, [name]: false });
    console.log(value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  function numberWithCommas(x) {
    if (x < 0 || x === "" || x === undefined || x === null) {
      return;
    }
    let number = parseInt(x);
    let nf = new Intl.NumberFormat("en-US");
    return nf.format(number);
  }

  const onSubmit = async () => {
    let errors = {};
    formFields.forEach((field) => {
      if (
        incomeFields.includes(field.name) &&
        excludeList.current.includes(field.name) &&
        (formData[field.key] === null ||
          formData[field.key] === undefined ||
          formData[field.key] === "")
      ) {
        errors = { ...errors, [field.key]: true };
      }
    });
    console.log(errors);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      updateLoading(true);
      let dealId = readDealId();
      console.log(dealId);
      let response = await saveAssetAndIncome(formData, dealId);
      updateLoading(false);
      navigate("/liabilitiesExpence");
    } catch (error) {
      console.log(error);
      updateLoading(false);
    }
  };
  const onChangeRadio = (key, value, name) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <>
      <div className="in__ex__container">
        <Card>
          <div className="in__ex__card">
            <div className="title">Just a few fun financial questions </div>
            <div className="income__wrap">
              <div className="income__header">Income</div>
              <div className="income__fields__wrap">
                {formFields.map((entry) => {
                  return incomeFields.includes(entry.name) ? (
                    entry.name === "Partner_Income_Per_Month" &&
                    !isMarried ? null :entry.name === "Partner_Income_Per_Month" &&isJointApplication?null: (
                      <div className="expense__input__container input-wrap-row">
                        <div>{fetchLabelText(entry.name)}</div>
                        <label data-domain="$">
                          <input
                            autoComplete="off"
                            className={`income__input ${
                              formErrors[entry.key] ? `error` : ``
                            }`}
                            name={entry.key}
                            onChange={onInputChange}
                            value={formData[entry.key] || 0}
                          />
                        </label>
                      </div>
                    )
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div className="income__fields__wrap">
                {formFields.map((entry) => {
                  return payDecreased.includes(entry.name) ? (
                    <div className="expense__input__container radio__wrapper">
                      <div className="radio_label">
                        Would any of this income decrease in the next 12 months?
                      </div>
                      <div className="radio_select">
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio"
                            className="checkbox-custom"
                            type={"radio"}
                            value="Yes"
                            onClick={() => {
                              onChangeRadio(entry.key, "Yes", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio"
                            className="range__label checkbox-custom-label"
                          >
                            <div>Yes</div>
                          </label>
                        </div>
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio-2"
                            type={"radio"}
                            value="No"
                            className="checkbox-custom"
                            onClick={() => {
                              onChangeRadio(entry.key, "No", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio-2"
                            className="range__label checkbox-custom-label"
                          >
                            <div>No</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div className="income__fields__wrap margin__top">
                {formFields.map((entry) => {
                  return selfEmployed.includes(entry.name) ? (
                    <div className="expense__input__container radio__wrapper">
                      <div className="radio_label">Self Employed :</div>
                      <div className="radio_select">
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio3"
                            className="checkbox-custom"
                            type={"radio"}
                            value="Yes"
                            onClick={() => {
                              onChangeRadio(entry.key, "Yes", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio3"
                            className="range__label checkbox-custom-label"
                          >
                            <div>Yes</div>
                          </label>
                        </div>
                        <div className="flex-row reduce_expense_radio">
                          <input
                            id="pays-radio-4"
                            type={"radio"}
                            value="No"
                            className="checkbox-custom"
                            onClick={() => {
                              onChangeRadio(entry.key, "No", entry.name);
                            }}
                            name={entry.key}
                          />
                          <label
                            for="pays-radio-4"
                            className="range__label checkbox-custom-label"
                          >
                            <div>No</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div
                className={`expense__fields__wrap margin__top ${
                  formData[extractAPIKey("Self_Employed")] === "No"
                    ? ``
                    : `hide`
                }`}
              >
                {formFields.map((entry) => {
                  return employementFields.includes(entry.name) ? (
                    entry.name == "Current_Job_Description" ? (
                      <div className="expense__input__container input-wrap-row" style={{display: "flex", flexDirection: "row"}}>
                        <div>{fetchLabelText(entry.name)}</div>
                        <select
                           autoComplete="off"
                           placeholder={entry.name.split("_").join(" ")}
                           className={`income__select ${
                             formErrors[entry.key] ? `error` : ``
                           }`}
                           name={entry.key}
                           onChange={onInputChange}
                           value={formData[entry.key]}
                        >
                          <option value="" selected="">{entry.name.split("_").join(" ")}</option>
                          <option value="Arts and Media Professionals">
                            Arts and Media Professionals
                          </option>
                          <option value="Automotive and Engineering Trades Workers">
                            Automotive and Engineering Trades Workers
                          </option>
                          <option value="Business, Human Resource and Marketing Professionals">
                            Business, Human Resource and Marketing Professionals
                          </option>
                          <option value="Carers and Aides">
                            Carers and Aides
                          </option>
                          <option value="Chief Executives, General Managers and Legislators">
                            Chief Executives, General Managers and Legislators
                          </option>
                          <option value="Cleaners and Laundry Workers">
                            Cleaners and Laundry Workers
                          </option>
                          <option value="Clerical and Office Support Workers">
                            Clerical and Office Support Workers
                          </option>
                          <option value="Construction and Mining Labourers">
                            Construction and Mining Labourers
                          </option>
                          <option value="Construction Trades Workers">
                            Construction Trades Workers
                          </option>
                          <option value="Design, Engineering, Science and Transport Professionals">
                            Design, Engineering, Science and Transport
                            Professionals
                          </option>
                          <option value="Education Professionals">
                            Education Professionals
                          </option>
                          <option value="Electrotechnology and Telecommunications Trades Workers">
                            Electrotechnology and Telecommunications Trades
                            Workers
                          </option>
                          <option value="Engineering, ICT and Science Technicians">
                            Engineering, ICT and Science Technicians
                          </option>
                          <option value="Factory Process Workers">
                            Factory Process Workers
                          </option>
                          <option value="Farm, Forestry and Garden Workers">
                            Farm, Forestry and Garden Workers
                          </option>
                          <option value="Farmers and Farm Managers">
                            Farmers and Farm Managers
                          </option>
                          <option value="Food Preparation Assistants">
                            Food Preparation Assistants
                          </option>
                          <option value="Food Trades Workers">
                            Food Trades Workers
                          </option>
                          <option value="General Clerical Workers">
                            General Clerical Workers
                          </option>
                          <option value="Health and Welfare Support Workers">
                            Health and Welfare Support Workers
                          </option>
                          <option value="Health Professionals">
                            Health Professionals
                          </option>
                          <option value="Hospitality Workers">
                            Hospitality Workers
                          </option>
                          <option value="Hospitality, Retail and Service Managers">
                            Hospitality, Retail and Service Managers
                          </option>
                          <option value="House wife/husband">
                            House wife/husband
                          </option>
                          <option value="ICT Professionals">
                            ICT Professionals
                          </option>
                          <option value="Inquiry Clerks and Receptionists">
                            Inquiry Clerks and Receptionists
                          </option>
                          <option value="Invalid pensioner">
                            Invalid pensioner
                          </option>
                          <option value="Legal, Social and Welfare Professionals">
                            Legal, Social and Welfare Professionals
                          </option>
                          <option value="Machine and Stationary Plant Operators">
                            Machine and Stationary Plant Operators
                          </option>
                          <option value="Mobile Plant Operators">
                            Mobile Plant Operators
                          </option>
                          <option value="Numerical Clerks">
                            Numerical Clerks
                          </option>
                          <option value="Office Managers and Program Administrators">
                            Office Managers and Program Administrators
                          </option>
                          <option value="Other Clerical and Administrative Workers">
                            Other Clerical and Administrative Workers
                          </option>
                          <option value="Other Labourers">
                            Other Labourers
                          </option>
                          <option value="Other Occupation (Inadequately Described)">
                            Other Occupation (Inadequately Described)
                          </option>
                          <option value="Other pensioner">
                            Other pensioner
                          </option>
                          <option value="Other Technicians and Trades Workers">
                            Other Technicians and Trades Workers
                          </option>
                          <option value="Personal Assistants and Secretaries">
                            Personal Assistants and Secretaries
                          </option>
                          <option value="Protective Service Workers">
                            Protective Service Workers
                          </option>
                          <option value="Retired">Retired</option>
                          <option value="Road and Rail Drivers">
                            Road and Rail Drivers
                          </option>
                          <option value="Sales Assistants and Salespersons">
                            Sales Assistants and Salespersons
                          </option>
                          <option value="Sales Representatives and Agents">
                            Sales Representatives and Agents
                          </option>
                          <option value="Sales Support Workers">
                            Sales Support Workers
                          </option>
                          <option value="Skilled Animal and Horticultural Workers">
                            Skilled Animal and Horticultural Workers
                          </option>
                          <option value="Specialist Managers">
                            Specialist Managers
                          </option>
                          <option value="Sports and Personal Service Workers">
                            Sports and Personal Service Workers
                          </option>
                          <option value="Storepersons">Storepersons</option>
                          <option value="Student">Student</option>
                          <option value="Unemployed">Unemployed</option>
                        </select>
                      </div>
                    ) : (
                      <div className="expense__input__container input-wrap-row" style={{position: "relative"}}>
                        <div style={entry.name=="Current_Employment_Industry"?{position: "absolute",marginTop: "1rem"}:{}}>{fetchLabelText(entry.name)}</div>
                        <label data-domain="">
                          {dropdownList.includes(entry.name) ? entry.name=="Current_Employment_Industry"?(<select
                              autoComplete="off"
                              className={`income__select ${
                                formErrors[entry.key] ? `error` : ``
                              }`}
                              name={entry.key}
                              onChange={(e)=>{
                                console.log(e.target.validationMessage);
                                onInputChange(e);
                              }}
                              value={formData[entry.key]}
                            >
                              {renderOptions(entry).map((entry) => {
                                return (
                                  <option
                                    className="width__100"
                                    value={entry.label}
                                  >
                                    {entry.label}
                                  </option>
                                );
                              })}
                            </select>):(
                            <select
                              autoComplete="off"
                              className={`income__select ${
                                formErrors[entry.key] ? `error` : ``
                              }`}
                              name={entry.key}
                              onChange={(e)=>{
                                console.log(e.target.validationMessage);
                                onInputChange(e);
                              }}
                              value={formData[entry.key]}
                            >
                              {renderOptions(entry).map((entry) => {
                                return (
                                  <option
                                    className="width__100"
                                    value={entry.value}
                                  >
                                    {entry.label}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <input
                              autoComplete="off"
                              className={`income__input ${
                                formErrors[entry.key] ? `error` : ``
                              }`}
                              name={entry.key}
                              onChange={onInputChange}
                              value={formData[entry.key]}
                            />
                          )}
                        </label>
                      </div>
                    )
                  ) : (
                    <></>
                  );
                })}
                <div className="input-wrap">
                  <div className="label">Time With Employer:</div>
                  <div className="input-wrap-line">
                    {formFields.map((_field) => {
                      return timeWithCurrentEmployer.includes(_field.name) ? (
                        <div className="dropdown_container">
                          <select
                            name={_field.key}
                            onChange={onInputChangeSimple}
                            className={formErrors[_field.name] ? `error` : ``}
                          >
                            {renderOptions(_field).map((optionValue) => {
                              return (
                                <option value={optionValue.value}>
                                  {optionValue.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
              <div
                className={`expense__fields__wrap margin__top ${
                  formData[extractAPIKey("Self_Employed")] === "No" &&
                  formData[extractAPIKey("Time_With_Employer_Years")] < 3
                    ? ``
                    : `hide`
                }`}
              >
                {formFields.map((entry) => {
                  return previousEmployementFields.includes(entry.name)?entry.name == "Previous_Job_Description" ? (
                    <div className="expense__input__container input-wrap-row" style={{display: "flex", flexDirection: "row"}}>
                      <div>{fetchLabelText(entry.name)}</div>
                      <select
                         autoComplete="off"
                         placeholder={entry.name.split("_").join(" ")}
                         className={`income__select ${
                           formErrors[entry.key] ? `error` : ``
                         }`}
                         name={entry.key}
                         onChange={onInputChange}
                         value={formData[entry.key]}
                      >
                        <option value="" selected="">{entry.name.split("_").join(" ")}</option>
                        <option value="Arts and Media Professionals">
                          Arts and Media Professionals
                        </option>
                        <option value="Automotive and Engineering Trades Workers">
                          Automotive and Engineering Trades Workers
                        </option>
                        <option value="Business, Human Resource and Marketing Professionals">
                          Business, Human Resource and Marketing Professionals
                        </option>
                        <option value="Carers and Aides">
                          Carers and Aides
                        </option>
                        <option value="Chief Executives, General Managers and Legislators">
                          Chief Executives, General Managers and Legislators
                        </option>
                        <option value="Cleaners and Laundry Workers">
                          Cleaners and Laundry Workers
                        </option>
                        <option value="Clerical and Office Support Workers">
                          Clerical and Office Support Workers
                        </option>
                        <option value="Construction and Mining Labourers">
                          Construction and Mining Labourers
                        </option>
                        <option value="Construction Trades Workers">
                          Construction Trades Workers
                        </option>
                        <option value="Design, Engineering, Science and Transport Professionals">
                          Design, Engineering, Science and Transport
                          Professionals
                        </option>
                        <option value="Education Professionals">
                          Education Professionals
                        </option>
                        <option value="Electrotechnology and Telecommunications Trades Workers">
                          Electrotechnology and Telecommunications Trades
                          Workers
                        </option>
                        <option value="Engineering, ICT and Science Technicians">
                          Engineering, ICT and Science Technicians
                        </option>
                        <option value="Factory Process Workers">
                          Factory Process Workers
                        </option>
                        <option value="Farm, Forestry and Garden Workers">
                          Farm, Forestry and Garden Workers
                        </option>
                        <option value="Farmers and Farm Managers">
                          Farmers and Farm Managers
                        </option>
                        <option value="Food Preparation Assistants">
                          Food Preparation Assistants
                        </option>
                        <option value="Food Trades Workers">
                          Food Trades Workers
                        </option>
                        <option value="General Clerical Workers">
                          General Clerical Workers
                        </option>
                        <option value="Health and Welfare Support Workers">
                          Health and Welfare Support Workers
                        </option>
                        <option value="Health Professionals">
                          Health Professionals
                        </option>
                        <option value="Hospitality Workers">
                          Hospitality Workers
                        </option>
                        <option value="Hospitality, Retail and Service Managers">
                          Hospitality, Retail and Service Managers
                        </option>
                        <option value="House wife/husband">
                          House wife/husband
                        </option>
                        <option value="ICT Professionals">
                          ICT Professionals
                        </option>
                        <option value="Inquiry Clerks and Receptionists">
                          Inquiry Clerks and Receptionists
                        </option>
                        <option value="Invalid pensioner">
                          Invalid pensioner
                        </option>
                        <option value="Legal, Social and Welfare Professionals">
                          Legal, Social and Welfare Professionals
                        </option>
                        <option value="Machine and Stationary Plant Operators">
                          Machine and Stationary Plant Operators
                        </option>
                        <option value="Mobile Plant Operators">
                          Mobile Plant Operators
                        </option>
                        <option value="Numerical Clerks">
                          Numerical Clerks
                        </option>
                        <option value="Office Managers and Program Administrators">
                          Office Managers and Program Administrators
                        </option>
                        <option value="Other Clerical and Administrative Workers">
                          Other Clerical and Administrative Workers
                        </option>
                        <option value="Other Labourers">
                          Other Labourers
                        </option>
                        <option value="Other Occupation (Inadequately Described)">
                          Other Occupation (Inadequately Described)
                        </option>
                        <option value="Other pensioner">
                          Other pensioner
                        </option>
                        <option value="Other Technicians and Trades Workers">
                          Other Technicians and Trades Workers
                        </option>
                        <option value="Personal Assistants and Secretaries">
                          Personal Assistants and Secretaries
                        </option>
                        <option value="Protective Service Workers">
                          Protective Service Workers
                        </option>
                        <option value="Retired">Retired</option>
                        <option value="Road and Rail Drivers">
                          Road and Rail Drivers
                        </option>
                        <option value="Sales Assistants and Salespersons">
                          Sales Assistants and Salespersons
                        </option>
                        <option value="Sales Representatives and Agents">
                          Sales Representatives and Agents
                        </option>
                        <option value="Sales Support Workers">
                          Sales Support Workers
                        </option>
                        <option value="Skilled Animal and Horticultural Workers">
                          Skilled Animal and Horticultural Workers
                        </option>
                        <option value="Specialist Managers">
                          Specialist Managers
                        </option>
                        <option value="Sports and Personal Service Workers">
                          Sports and Personal Service Workers
                        </option>
                        <option value="Storepersons">Storepersons</option>
                        <option value="Student">Student</option>
                        <option value="Unemployed">Unemployed</option>
                      </select>
                    </div>): (
                    <div className="expense__input__container input-wrap-row">
                      <div style={entry.name=="Previous_Employment_Industry"?{position: "absolute",marginTop: "1rem"}:{}}>{fetchLabelText(entry.name)}</div>
                      <label data-domain="">
                        {dropdownList.includes(entry.name) ?entry.name=="Previous_Employment_Industry"?(<select
                              autoComplete="off"
                              className={`income__select ${
                                formErrors[entry.key] ? `error` : ``
                              }`}
                              name={entry.key}
                              onChange={(e)=>{
                                console.log(e.target.validationMessage);
                                onInputChange(e);
                              }}
                              value={formData[entry.key]}
                            >
                              {renderOptions(entry).map((entry) => {
                                return (
                                  <option
                                    className="width__100"
                                    value={entry.label}
                                  >
                                    {entry.label}
                                  </option>
                                );
                              })}
                            </select>): (
                          <select
                            autoComplete="off"
                            className={`income__select ${
                              formErrors[entry.key] ? `error` : ``
                            }`}
                            name={entry.key}
                            onChange={onInputChange}
                            value={formData[entry.key]}
                          >
                            {renderOptions(entry).map((entry) => {
                              return (
                                <option
                                  className="width__100"
                                  value={entry.value}
                                >
                                  {entry.label}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <input
                            autoComplete="off"
                            className={`income__input ${
                              formErrors[entry.key] ? `error` : ``
                            }`}
                            name={entry.key}
                            onChange={onInputChange}
                            value={formData[entry.key]}
                          />
                        )}
                      </label>
                    </div>
                  ) : (
                    <></>
                  );
                })}
                <div className="input-wrap">
                  <div className="label">Time With Previous Employer:</div>
                  <div className="input-wrap-line">
                    {formFields.map((_field) => {
                      return timeWithPreviousEmployer.includes(_field.name) ? (
                        <div className="dropdown_container">
                          <select
                            name={_field.key}
                            onChange={onInputChange}
                            className={formErrors[_field.name] ? `error` : ``}
                          >
                            {renderOptions(_field).map((optionValue) => {
                              return (
                                <option value={optionValue.value}>
                                  {optionValue.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="expense__wrap">
              <div className="income__header">
                Whats the total value of your Assets :
              </div>
              <div className="expense__fields__wrap">
                {formFields.map((entry) => {
                  return valueFields.includes(entry.name) ? (
                    entry.name === "Value_Home" && !isMortaged ? null : (
                      <div className="expense__input__container input-wrap-row">
                        <div>{fetchLabelText(entry.name)}</div>
                        <label data-domain="$">
                          <input
                            autoComplete="off"
                            className={`income__input ${
                              formErrors[entry.key] ? `error` : ``
                            }`}
                            name={entry.key}
                            onChange={onInputChange}
                            value={formData[entry.key] || 0}
                          />
                        </label>
                        <div className="expense__freequancy"></div>
                      </div>
                    )
                  ) : (
                    <></>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
        <div className="button__container">
          <ButtonPanel
            isPreviousEnabled={false}
            forwardMethod={async () => {
              await onSubmit();
            }}
          />
        </div>
      </div>
    </>
  );
};
export default IncomeAsset;
