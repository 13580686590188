export const expenceFields = [
    "House_Expense",
    "Insurance_Expense",
    "Utilities_Expense",
    "Other_Expense",
    "Personal_Expense",
    "Living_Expense",
  ];
  export  const creditCardsNoField = ["No_Of_Credit_Cards"];
  export  const mortageFields = ["Mortgage_Provider", "Mortgage_Balance","Mortgage_Repayment_Amount"];
  export  const buyNowPayLater = ["Have_Buy_Now_Pay_Later"];
  export const buyNowPayLaterFields = [
    "Buy_Now_Pay_Later_Provider",
    "Buy_Now_Pay_Later_Owing",
  ];
  export const personalLoan = ["Have_Personal_Loan"];
  export const personalLoanFields = [
    "Personal_Loan_Provider",
    "Personal_Loan_Balance",
    "Personal_Loan_Repayment_Amount",
  ];
  export const carLoan = ["Have_Car_Loan"];
  export const carLoanFields = [
    "Car_Loan_Provider",
    "Car_Loan_Balance",
    "Car_Loan_Repayment_Amount",
  ];
  