export const validationExcludeList = ["Joint_Applicant_Mid_Name"];
  export const personalInfo = [
    "Joint_Applicant_First_Name",
    "Joint_Applicant_Mid_Name",
    "Joint_Applicant_Last_Name",
    "Joint_Applicant_Gender",
    "Joint_Applicant_Mobile",
    "Joint_Applicant_Email",
    "Joint_Applicant_No_Of_Depenants",
    "Joint_Applicant_Country_Of_Citizenship",
    "Joint_Applicant_Country_Of_Birth",
    "Joint_Applicant_Marital_Status",
    "Joint_Applicant_Driver_Licence_Type",
    "Joint_Applicant_Driver_Licence_Number",
    "Joint_Applicant_Driver_Licence_Vesrion",
    "Joint_Applicant_Resident_Type",
  ];
  export const datePicker = ["Joint_Applicant_DOB","Joint_Applicant_Licence_Expiry"];
  export const addressInfo = ["Joint_Applicant_City", "Joint_Applicant_Postal_Code", "Joint_Applicant_Street_Name"];
  export const addressPreviousInfo = [
    "Joint_Applicant_Previous_City",
    "Joint_Applicant_Previous_Postal_Code",
    "Joint_Applicant_Previous_Street_Name",
  ];
  export const dropdowns = [
    "Joint_Applicant_No_Of_Depenants",
    "Joint_Applicant_Country_Of_Citizenship",
    "Joint_Applicant_Country_Of_Birth",
    "Joint_Applicant_Driver_Licence_Type",
    "Joint_Applicant_Gender",
    "Joint_Applicant_Marital_Status",
    "Joint_Applicant_Resident_Years",
    "Joint_Applicant_Resident_Months",
    "Joint_Applicant_Resident_Type",
  ];
  export const creditCheckFields = [
    "Joint_Applicant_Gender",
    "Joint_Applicant_DOB",
    "Joint_Applicant_First_Name",
    "Joint_Applicant_Last_Name",
    "Joint_Applicant_City",
    "Joint_Applicant_Street_Name",
    "Joint_Applicant_Postal_Code",
  ];
  export const residentTime = ["Joint_Applicant_Resident_Years", "Joint_Applicant_Resident_Months"];
  export const nzResident = ["Joint_Applicant_New_Zealand_Permanent_Resident"];