import React, { useContext } from "react";
import { BrowserRouter, Route,  Routes } from "react-router-dom";
import { AppContext } from "./app_provider";
import Footer from "./common/components/footer/footer";
import Header from "./common/components/header/header";
import Landing from "./screens/landing";
import Alert from "./common/components/other/alert";
import ApplicationType from "./screens/application_type";
import ApplicationPrerquisties from "./screens/application_prerequisties";
import ApplicationMode from "./screens/application_mode";
import Loading from "./common/components/other/loader";
import PersonalInformation from "./screens/personal_information";
import FileUploads from "./screens/files_upload";
import Illion from "./screens/illion";
import Thanks from "./screens/thanks";
import './App.css'
import IncomeAsset from "./screens/income_asset";
import LiabilitiesExpence from "./screens/liabilities_expence";
import JointApplicantPersonalInformation from "./screens/personal_information_joint";
import JointApplicantIncomeAsset from "./screens/income_asset_joint";
import JointApplicantLiabilitiesExpence from "./screens/liabilities_expence_joint";
import FileUploadsJoint from "./screens/files_upload_joint";
import IllionJoint from "./screens/illion_joint";

const Finance = () => {
  const { alertTitle, alertContent, showAlert, closeAlert, isLoading } =
    useContext(AppContext);
  return (
    <div className="primaryScreen">
      <div>
        <Header />
      </div>
      <div className="body-container">
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<Landing />} />
            <Route
              path="/applicationType"
              exact
              element={<ApplicationType />}
            />
            <Route
              path="/prerequisties"
              exact
              element={<ApplicationPrerquisties />}
            />
             <Route
              path="/applicationMode"
              exact
              element={<ApplicationMode />}
            />
            <Route
              path="/personalInfo"
              exact
              element={<PersonalInformation />}
            />
             <Route
              path="/incomeAsset"
              exact
              element={<IncomeAsset />}
            />
             <Route
              path="/liabilitiesExpence"
              exact
              element={<LiabilitiesExpence/>}
            />
             <Route
              path="/fileUploads/:dealId"
              exact
              element={<FileUploads />}
            />
             <Route
              path="/fileUploadsJoint/:dealId"
              exact
              element={<FileUploadsJoint />}
            />
             <Route
              path="/fileUploads"
              exact
              element={<FileUploads />}
            />
              <Route
              path="/illion"
              exact
              element={<Illion />}
            />
              <Route
              path="/illionJoint"
              exact
              element={<IllionJoint />}
            />
             <Route
              path="/thanks"
              exact
              element={<Thanks />}
            />
            <Route
            path="/personalInfo/:dealId"
            exact
            element={<JointApplicantPersonalInformation/>}
            />
             <Route
            path="/incomeAsset/:dealId"
            exact
            element={<JointApplicantIncomeAsset/>}
            />
            <Route
            path="/personalInfo/:dealId"
            exact
            element={<JointApplicantPersonalInformation/>}
            />
             <Route
            path="/liabilitiesExpence/:dealId"
            exact
            element={<JointApplicantLiabilitiesExpence/>}
            />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
      <Alert
        alertContent={alertContent}
        alertTitle={alertTitle}
        showAlert={showAlert}
        closeAlert={closeAlert}
      />
      <Loading
      showLoading = {isLoading}
      />
    </div>
  );
};
export default Finance;
