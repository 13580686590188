
import {
    fetchDealFieldsFromApp,
    downloadFieldsPipedrive,
    saveDealFieldsToApp,
    storeResidenceYears,
    storeFirstName,
    readResidenceYears,
    storeDealFormFields,
    fetchDealFormFieldsFromApp,
    extractAPIKey,
    storeMarriageStatus,
    storeMortageStatus,
    storePRStatus,
  } from "../../utilities/methods";
import { addressInfo, addressPreviousInfo, creditCheckFields, datePicker, dropdowns, personalInfo, residentTime } from "./const";

export const storeFieldsKeys = (fields) => {
    getApiKeyOfYears(fields);
    getApiKeyFirstName(fields);
    let formFields = fetchDealFormFieldsFromApp();
  };
  export const getApiKeyOfYears = (fields) => {
    let list = fields.find((entry) => {
      return entry.name === "Resident_Years";
    });
    storeResidenceYears(list.key);
  };
  export const getApiKeysOfCreditCheckFields = (fields) => {
    let creditCheckKeyFields = [];
    fields.forEach((field) => {
      if (creditCheckFields.includes(field.name)) {
        creditCheckKeyFields.push(field);
      }
    });
    return creditCheckKeyFields;
  };
  export const getApiKeyFirstName = (fields) => {
    let list = fields.find((entry) => {
      return entry.name === "First_Name";
    });
    storeFirstName(list.key);
  };
  export const downloadDealFields = async (updateLoading,retryCount,setRetryCount) => {
    updateLoading(true);
    try {
      localStorage.removeItem("INVOKED");
      let _dealFields = await downloadFieldsPipedrive();
      saveDealFieldsToApp(_dealFields);
      updateLoading(false);
    } catch (error) {
      if (retryCount === undefined) {
        setRetryCount(1);
        return;
      }
      setRetryCount(retryCount + 1);
    }
  };
  export const createCompleteFieldsList = () => {
    let fields = fetchDealFieldsFromApp();
    let fieldsFromForm = [
      ...personalInfo,
      ...addressInfo,
      ...addressPreviousInfo,
      ...residentTime,
      ...datePicker,
    ];
    let fieldList = [];
    fields.forEach((field) => {
      if (fieldsFromForm.includes(field.name)) {
        fieldList.push(field);
      }
    });
    return fieldList;
  };
  export const createPersonalFormJson = (fields) => {
    let formJson = [];
    fields.forEach((field) => {
      let isIncludedInPersonalInfo = personalInfo.includes(field.name);

      let preparingForm = {};
      let isIncludedInDatePicker = datePicker.includes(field.name);
      if (isIncludedInDatePicker) {
        preparingForm = {
          name: field.key,
          referenceName: field.name,
          type: "date",
          ...constructHints(field.name),
        };
        formJson.push(preparingForm);
      }
      if (isIncludedInPersonalInfo) {
        let isincludedInDropdown = dropdowns.includes(field.name);

        if (isincludedInDropdown) {
          preparingForm = populateDropdownToFormJson(field);
          formJson.push(preparingForm);
        }

        if (!isincludedInDropdown) {
          preparingForm = populateInputToFormJson(field);
          formJson.push(preparingForm);
        }
      }
    });
    return formJson;
  };
  export const renderOptions = (entry) => {
    switch (entry.referenceName) {
      case "No_Of_Depenants":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          {
            value: 0,
            label: "0",
          },
          {
            value: 1,
            label: "1",
          },
          {
            value: 2,
            label: "2",
          },
          {
            value: 3,
            label: "3",
          },
          {
            value: 4,
            label: "4",
          },
          {
            value: 5,
            label: "5",
          },
          {
            value: 6,
            label: "6",
          },
          {
            value: 7,
            label: "7",
          },
        ];
      case "Driver_Licence_Type":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          {
            value: "Car Licence",
            label: "Car Licence",
          },
          {
            value: "Truck Licence",
            label: "Truck Licence",
          },
          {
            value: "Motorcycle Licence",
            label: "Motorcycle Licence",
          },
          {
            value: "Learner Licence",
            label: "Learner Licence",
          },
          {
            value: "Restricted Licence",
            label: "Restricted Licence",
          },
        ];
      case "Country_Of_Birth":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          ...getCountrylist(),
        ];
      case "Country_Of_Citizenship":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          ...getCountrylist(),
        ];
      case "Gender":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          {
            value: "male",
            label: "Male",
          },

          {
            value: "female",
            label: "Female",
          },
          {
            value: "gender_divers",
            label: "Gender Divers",
          },
          {
            value: "other",
            label: "Other",
          },
        ];
      case "Marital_Status":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          {
            value: "Single",
            label: "Single",
          },

          {
            value: "Married",
            label: "Married / Civil Union",
          },
          {
            value: "De Facto",
            label: "De Facto",
          },
          {
            value: "Separated",
            label: "Separated",
          },
          {
            value: "Divorced",
            label: "Divorced",
          },
          {
            value: "Widowed",
            label: "Widowed",
          },
        ];
        case "Title":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          {
            value: "Miss",
            label: "Miss",
          },

          {
            value: "Mr",
            label: "Mr",
          },
          {
            value: "Defacto",
            label: "Defacto",
          },
          {
            value: "Mrs",
            label: "Mrs",
          },
        ];
      case "Resident_Type":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          {
            value: "Boarding",
            label: "Boarding",
          },

          {
            value: "Caravan",
            label: "Caravan",
          },
          {
            value: "Other",
            label: "Other",
          },
          {
            value: "Own Home",
            label: "Own Home",
          },
          {
            value: "Own Home Mortgage",
            label: "Own Home Mortgaged",
          },
          {
            value: "Renting",
            label: "Renting",
          },
          {
            value: "Supplied by Employer",
            label: "Supplied by Employer",
          },
          {
            value: "With Parents",
            label: "With Parents",
          },
        ];
      case "Resident_Months":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          {
            value: "0",
            label: "0",
          },
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "5",
            label: "5",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "7",
            label: "7",
          },
          {
            value: "8",
            label: "8",
          },
          {
            value: "9",
            label: "9",
          },
          {
            value: "10",
            label: "10",
          },
          {
            value: "11",
            label: "11",
          },
          {
            value: "12",
            label: "12",
          },
        ];
      case "Resident_Years":
        return [
          {
            value: undefined,
            label: entry.hintText,
          },
          {
            value: "0",
            label: "0",
          },
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "5",
            label: "5",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "7",
            label: "7",
          },
          {
            value: "8",
            label: "8",
          },
          {
            value: "9",
            label: "9",
          },
          {
            value: "10",
            label: "10",
          },
          {
            value: "10+",
            label: "10+",
          },
        ];
      default:
        return [
          {
            value: 1,
            label: "1",
          },
          {
            value: 2,
            label: "2",
          },
          {
            value: 3,
            label: "3",
          },
          {
            value: 4,
            label: "4",
          },
          {
            value: 5,
            label: "5",
          },
          {
            value: 6,
            label: "6",
          },
          {
            value: 7,
            label: "7",
          },
          {
            value: 8,
            label: "8",
          },
          {
            value: 9,
            label: "9",
          },
          {
            value: 10,
            label: "10",
          },
          {
            value: 11,
            label: "11",
          },
          {
            value: 12,
            label: "12",
          },
        ];
    }
  };
  export const getCountrylist = () => [
    { label: "New Zealand", value: "NZ" },
    { label: "Australia", value: "AU" },
    { label: "Afghanistan", value: "AF" },
    { label: "Åland Islands", value: "AX" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "American Samoa", value: "AS" },
    { label: "AndorrA", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctica", value: "AQ" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bermuda", value: "BM" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Bouvet Island", value: "BV" },
    { label: "Brazil", value: "BR" },
    { label: "British Indian Ocean Territory", value: "IO" },
    { label: "Brunei Darussalam", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Cape Verde", value: "CV" },
    { label: "Cayman Islands", value: "KY" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Christmas Island", value: "CX" },
    { label: "Cocos (Keeling) Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "Congo, The Democratic Republic of the", value: "CD" },
    { label: "Cook Islands", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: 'Cote D"Ivoire', value: "CI" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Ethiopia", value: "ET" },
    { label: "Falkland Islands (Malvinas)", value: "FK" },
    { label: "Faroe Islands", value: "FO" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "French Guiana", value: "GF" },
    { label: "French Polynesia", value: "PF" },
    { label: "French Southern Territories", value: "TF" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Greece", value: "GR" },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guadeloupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernsey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Heard Island and Mcdonald Islands", value: "HM" },
    { label: "Holy See (Vatican City State)", value: "VA" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran, Islamic Republic Of", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Isle of Man", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: 'Korea, Democratic People"S Republic of', value: "KP" },
    { label: "Korea, Republic of", value: "KR" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: 'Lao People"S Democratic Republic', value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libyan Arab Jamahiriya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Martinique", value: "MQ" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia, Federated States of", value: "FM" },
    { label: "Moldova, Republic of", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montserrat", value: "MS" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "Netherlands Antilles", value: "AN" },
    { label: "New Caledonia", value: "NC" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Norfolk Island", value: "NF" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Palestinian Territory, Occupied", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Qatar", value: "QA" },
    { label: "Reunion", value: "RE" },
    { label: "Romania", value: "RO" },
    { label: "Russian Federation", value: "RU" },
    { label: "RWANDA", value: "RW" },
    { label: "Saint Helena", value: "SH" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Pierre and Miquelon", value: "PM" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia and Montenegro", value: "CS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Georgia and the South Sandwich Islands", value: "GS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Svalbard and Jan Mayen", value: "SJ" },
    { label: "Swaziland", value: "SZ" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syrian Arab Republic", value: "SY" },
    { label: "Taiwan, Province of China", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania, United Republic of", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Turks and Caicos Islands", value: "TC" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States", value: "US" },
    { label: "United States Minor Outlying Islands", value: "UM" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela", value: "VE" },
    { label: "Viet Nam", value: "VN" },
    { label: "Virgin Islands, British", value: "VG" },
    { label: "Virgin Islands, U.S.", value: "VI" },
    { label: "Wallis and Futuna", value: "WF" },
    { label: "Western Sahara", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" },
  ];
  export const createAddressFormJson = (fields) => {
    let formJson = [];
    fields.forEach((field) => {
      let isIncludedInAddressInfo = addressInfo.includes(field.name);

      let preparingForm = {};

      if (isIncludedInAddressInfo) {
        // let isIncludedInRadio = radio.includes(field.name);
        // if (isIncludedInRadio) {
        //   preparingForm = {
        //     name: field.key,
        //     referenceName: field.name,
        //     type: "radio",
        //     ...constructHints(field.name),
        //   };
        //   formJson.push(preparingForm);
        // }
        let isincludedInDropdown = dropdowns.includes(field.name);
        if (isincludedInDropdown) {
          preparingForm = populateDropdownToFormJson(field);
          formJson.push(preparingForm);
        }

        if (!isincludedInDropdown) {
          preparingForm = populateInputToFormJson(field);
          formJson.push(preparingForm);
        }
      }
    });
    return formJson;
  };
  export const createPreviousAddressFormJson = (fields) => {
    let formJson = [];
    fields.forEach((field) => {
      let isIncludedInAddressInfo = addressPreviousInfo.includes(field.name);

      let preparingForm = {};

      if (isIncludedInAddressInfo) {
        let isincludedInDropdown = dropdowns.includes(field.name);
        if (isincludedInDropdown) {
          preparingForm = populateDropdownToFormJson(field);
          formJson.push(preparingForm);
        }

        if (!isincludedInDropdown) {
          preparingForm = populateInputToFormJson(field);
          formJson.push(preparingForm);
        }
      }
    });
    return formJson;
  };
  export const createResidenceFormJson = (fields) => {
    let formJson = [];
    fields.forEach((field) => {
      let isIncluded = residentTime.includes(field.name);

      let preparingForm = {};

      if (isIncluded) {
        let isincludedInDropdown = dropdowns.includes(field.name);
        if (isincludedInDropdown) {
          preparingForm = populateDropdownToFormJson(field);
          formJson.push(preparingForm);
        }

        if (!isincludedInDropdown) {
          preparingForm = populateInputToFormJson(field);
          formJson.push(preparingForm);
        }
      }
    });
    return formJson;
  };
  export const populateInputToFormJson = (field) => {
    console.log(field);
    return {
      name: field.key,
      referenceName: field.name,
      type: datePicker.includes(field.name)?"date":"text",
      ...constructHints(field.name),
    };
  };
  export const populateDropdownToFormJson = (field) => {
    return {
      name: field.key,
      referenceName: field.name,
      type: "dropdown",
      ...constructHints(field.name),
    };
  };
  export const getApiKeyOfCreditScore = (fields) => {
    let apiKey = "";
    fields.forEach((field) => {
      if (field.name === "Credit_Score") {
        apiKey = field.key;
      }
    });

    return apiKey;
  };
  export const constructHints = (referenceName) => {
    switch (referenceName) {
      case "Title":
        return {
          hintText: "Title",
          labelText: "Title",
          sortOrder: 1,
        };
      case "First_Name":
        return {
          hintText: "First Name",
          labelText: "First Name",
          sortOrder: 1,
        };
      case "Mid_Name":
        return { hintText: "Mid Name", labelText: "Midname", sortOrder: 2 };
      case "Last_Name":
        return { hintText: "Surname", labelText: "Surname", sortOrder: 3 };
      case "DOB":
        return {
          hintText: "mm/dd/yyyy",
          labelText: "Date of Birth",
          sortOrder: 7,
        };
      case "Mobile":
        return {
          hintText: "Mobile Number",
          labelText: "Mobile Number",
          sortOrder: 5,
        };
      case "Email":
        return {
          hintText: "Email Address",
          labelText: "Email Address",
          sortOrder: 6,
        };
      case "Marital_Status":
        return {
          hintText: "Marital Status",
          labelText: "Marital Status",
          sortOrder: 8,
        };
      case "No_Of_Depenants":
        return {
          hintText: "Select one Option",
          labelText: "Dependents you have?",
          sortOrder: 9,
        };
      case "Driver_Licence_Type":
        return {
          hintText: "Select Type",
          labelText: "Driver Licence Type",
          sortOrder: 10,
        };
      case "Driver_Licence_Number":
        return {
          hintText: "Eg. DV12345",
          labelText: "Driver Licence Number",
          sortOrder: 11,
        };
      case "Driver_Licence_Vesrion":
        return {
          hintText: "Eg. 345",
          labelText: "Driver Licence Version",
          sortOrder: 12,
        };
        case "Licence_Expiry":
          return {
            hintText: "Licence Expiry",
            labelText: "Licence Expiry",
            sortOrder: 13,
          };
      case "Country_Of_Birth":
        return {
          hintText: "Select Country",
          labelText: "Country of Birth",
          sortOrder: 14,
        };
      case "Country_Of_Citizenship":
        return {
          hintText: "Select Country",
          labelText: "Country of Citizenship",
          sortOrder: 15,
        };
      case "City":
        return { hintText: "City", labelText: "City", sortOrder: 16 };
      case "Postal_Code":
        return {
          hintText: "Postal Code",
          labelText: "Postal Code",
          sortOrder: 17,
        };
      case "Expected_Amount":
        return {
          hintText: "Expected Amount",
          labelText: "Expected Amount",
          sortOrder: 20,
        };
      case "Street_Number":
        return {
          hintText: "Street Number",
          labelText: "Street Number",
          sortOrder: 18,
        };
      case "Street_Name":
        return {
          hintText: "Street Name",
          labelText: "Street Name",
          sortOrder: 19,
        };
      case "Dependant_Locality":
        return {
          hintText: "Dependant Locality",
          labelText: "Dependant Locality",
          sortOrder: 20,
        };
      case "Gender":
        return { hintText: "Gender", labelText: "Gender", sortOrder: 4 };
      case "Previous_City":
        return {
          hintText: "Previous City",
          labelText: "Previous City",
          sortOrder: 21,
        };
      case "Previous_Postal_Code":
        return {
          hintText: "Previous Postal Code",
          labelText: "Previous Postal Code",
          sortOrder: 22,
        };
      case "Previous_Street_Number":
        return {
          hintText: "Previous Street Number",
          labelText: "Previous Street Number",
          sortOrder: 23,
        };
      case "Previous_Street_Name":
        return {
          hintText: "Previous Street Name",
          labelText: "Previous Street Name",
          sortOrder: 24,
        };
      case "Previous_Dependant_Locality":
        return {
          hintText: "Previous Dependant Locality",
          labelText: "Previous Dependant Locality",
          sortOrder: 25,
        };
      case "Resident_Years":
        return {
          hintText: "Years",
          labelText: "Previous Street Name",
          sortOrder: 26,
        };
      case "Resident_Months":
        return {
          hintText: "Months",
          labelText: "Previous Dependant Locality",
          sortOrder: 27,
        };
      case "Resident_Type":
        return {
          hintText: "Type",
          labelText: "Resident Type",
          sortOrder: 28,
        };
     
      default:
        return null;
    }
  };