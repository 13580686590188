import React from "react";
import "../../css/alert.css";

const Alert = (props) => {
  const { alertTitle, alertContent, closeAlert, showAlert } = props;
  return showAlert ? (
    <div className="alert">
      <div className="alertWrap">
        <div className="alertTitle">{alertTitle ?? "Test Alert"}</div>
        <div className="alertContent">
          {alertContent ??
            "This content is populated for the purpose of testing."}
        </div>
        <button className="button smallPadding primaryBackground" onClick={closeAlert}>Close</button>
      </div>
    </div>
  ) : null;
};
export default Alert;
