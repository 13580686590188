export const incomeFields = [
  "Joint_Applicant_Take_Home_Income_Per_Month",
  "Joint_Applicant_Partner_Income_Per_Month",
  "Joint_Applicant_Other_Income_Per_Month",
];
export const expenceFields = [
  "Joint_Applicant_Rates_Expense",
  "Joint_Applicant_Insurance_Expense",
  "Joint_Applicant_Utilities_Expense",
  "Joint_Applicant_Dependents_Expense",
  "Joint_Applicant_Mortgage_Or_Rent_Expense",
  "Joint_Applicant_Living_Expense",
];
export const valueFields = [
  "Joint_Applicant_Value_Home",
  "Joint_Applicant_Value_Property",
  "Joint_Applicant_Value_Vehicle",
  "Joint_Applicant_Value_Kiwisaver/Share",
  "Joint_Applicant_Value_Content",
];
export const liabilityFields = [
  "Joint_Applicant_Mortgage_Or_Rent_Liabilities",
  "Joint_Applicant_Loans_Liabilities",
  "Joint_Applicant_Credit_Cards_Liabilities",
];
export const employementFields = [
  "Joint_Applicant_Current_Employer",
  "Joint_Applicant_Current_Job_Description",
  "Joint_Applicant_Current_Employment_Status",
  "Time_With_Employer",
  "Joint_Applicant_Current_Employment_Industry",
];
export const previousEmployementFields = [
  "Joint_Applicant_Previous_Employer",
  "Joint_Applicant_Previous_Job_Description",
  "Joint_Applicant_Previous_Employment_Status",
];
export const timeWithCurrentEmployer = [
  "Joint_Applicant_Time_With_Employer_Years",
  "Joint_Applicant_Time_With_Employer_Months",
];
export const timeWithPreviousEmployer = [
  "Joint_Applicant_Time_With_Previous_Employer_Years",
  "Joint_Applicant_Time_With_Previous_Employer_Months",
];
export const dropdownList = [
  "Joint_Applicant_Current_Employment_Status",
  "Joint_Applicant_Previous_Employment_Status",
  "Joint_Applicant_Current_Employment_Industry"
];
export const payDecreased = ["Joint_Applicant_Pay_Decreased_In_One_Year"];
export const selfEmployed = ["Joint_Applicant_Self_Employed"];
