import axios from "axios";
import {
  getAccessToken,
  getBaseUrl,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "./local";

export const apexApiClient = axios.create({});

apexApiClient.interceptors.request.use(async (config) => {
  let token = getAccessToken();
  let baseUrl = getBaseUrl();
  if (token) {
    console.log(`Token ${token}`);
    config.headers = { "auth-token": `Bearer ${token}` };
  } else {
    await setAuthParameters(baseUrl);
    token = getAccessToken();
    config.headers = { "auth-token": `Bearer ${token}` };
  }
  config.baseURL = baseUrl;
  return config;
});
apexApiClient.interceptors.response.use(
  (_) => _,
  async(error) => {
    console.log(error);
    const {message} = error;
    const {status,config} = error.response;
    if(status===403){
        const {baseURL} =config;
        await refreshAuthParameters(baseURL);
        return Promise.reject({canResend: true,message: "Token expired kindly resend request"})
    }
    return Promise.reject(error);
  }
);
const setAuthParameters = async (baseUrl) => {
  let ipResponse = await axios.get(`https://api.ipify.org?format=json`);
  let clientConfig = {
    username : process.env.REACT_APP_USER,
    password: process.env.REACT_APP_PASSWORD
  };
  let authConfig = await axios.post(`${baseUrl}/auth/login`, clientConfig);
  console.log(authConfig);
  setAccessToken(authConfig.data.accessToken);
  setRefreshToken(authConfig.data.refreshToken);
};
const refreshAuthParameters = async (baseUrl) => {
    let refreshToken = getRefreshToken();

   let clientConfig = {
    username : process.env.REACT_APP_USER,
    password: process.env.REACT_APP_PASSWORD
  };
  let authConfig = await axios.post(`${baseUrl}/auth/login`, clientConfig);
  console.log(authConfig);
  setAccessToken(authConfig.data.accessToken);
  setRefreshToken(authConfig.data.refreshToken);
  };
