import react from "react";
import './others.css';
const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 15,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    //backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };
  const fillerStylesTransprent = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "inhert",
    borderRadius: "inherit",
    textAlign: "right",
    color: "grey",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div className="progressbar">
      <div style={fillerStylesTransprent}>
        {`${completed}%`}
      </div>
      <div style={containerStyles}>
        <div style={fillerStyles} className="primaryBackground"></div>
      </div>
      <div className="taggingContainer"><div>Start</div><div>Finish</div></div>
    </div>
  );
};

export default ProgressBar;
