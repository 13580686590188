import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../app_provider";
import {
  fetchDealFieldsFromApp,
  downloadFieldsPipedrive,
  saveDealFieldsToApp,
  storeResidenceYears,
  storeFirstName,
  readResidenceYears,
  storeDealFormFields,
  fetchDealFormFieldsFromApp,
  extractAPIKey,
  storeMarriageStatus,
  storeMortageStatus,
  storePRStatus,
  readDealId,
  storeRentingStatus,
} from "../../utilities/methods";
import ButtonPanel from "../../common/components/other/button_panel";
import "./personal_info.css";
import { useNavigate } from "react-router-dom";
import {
  checkCreditScore,
  fetchScoreFromApi,
  placeLookup,
  savePersonalInfo,
  updateADeal,
} from "../../utilities/api";
import AutoCompleteInput from "../../common/components/suggestion/suggestion";
import {
  createAddressFormJson,
  createCompleteFieldsList,
  createPersonalFormJson,
  createPreviousAddressFormJson,
  createResidenceFormJson,
  downloadDealFields,
  getApiKeyOfCreditScore,
  getApiKeysOfCreditCheckFields,
  renderOptions,
  storeFieldsKeys,
} from "./methods";
import {
  addressPreviousInfo,
  nzResident,
  validationExcludeList,
} from "./const";
const PersonalInformation = () => {
  const navigate = useNavigate();
  const {
    updateLoading,
    isLoading,
    setProgress,
    openAlert,
    setIsMarried,
    showToast,
  } = useContext(AppContext);
  const [personalInformation, setPersonalInfo] = useState([]);
  const [dealFields, setFields] = useState([]);
  const [addressInformation, setAddressInfo] = useState([]);
  const [previousAddressInformation, setPreviousAddressInfo] = useState([]);
  const [residentDuration, setResidentDuration] = useState([]);
  const [retryCount, setRetryCount] = useState();
  const [gender, setgender] = useState("male");
  const [previousVisible, setPreviosVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [suggestionList, setSuggestionList] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState({});
  const [suggestionListPrevious, setSuggestionListPrevious] = useState([]);
  const [previousSelectedSuggestion, setPreviousSelectedSuggestion] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    updateLoading(true);
    preLoadTasks();
  }, []);

  useEffect(() => {
    let apiKeyMaritalStatus;
    apiKeyMaritalStatus = extractAPIKey("Marital_Status");
    let apiKeyMortageStatus = extractAPIKey("Resident_Type");

    storeMarriageStatus(formData[apiKeyMaritalStatus] === "Married");
    storeRentingStatus(formData[apiKeyMortageStatus] === "Own Home Mortgage");
    console.log(formData[apiKeyMaritalStatus]);
    setIsMarried(formData[apiKeyMaritalStatus] === "married");
    let durationYearsKey = readResidenceYears();
    if (formData[durationYearsKey] < 3) {
      setPreviosVisible(true);
    } else {
      setPreviosVisible(false);
    }
    return () => {};
  }, [formData]);

  const preLoadTasks = async () => {
    let fields = fetchDealFieldsFromApp();
    setProgress(46);
    if (fields === null) {
      downloadDealFields(updateLoading, retryCount, setRetryCount);
    }
    fields = fetchDealFieldsFromApp();
    setFields(fields);
    let formConstructorPersonalInfo = createPersonalFormJson(fields);
    let formConstructorAddressInfo = createAddressFormJson(fields);
    let formConstructorPreviousAddressInfo =
      createPreviousAddressFormJson(fields);
    let formResidenceDuration = createResidenceFormJson(fields);

    let sortedpersonalInfoFields = formConstructorPersonalInfo.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    let sortedAddressInfoFields = formConstructorAddressInfo.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    let sortedPreviousAddressInfoFields =
      formConstructorPreviousAddressInfo.sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
    let sortedResidentDurationFields = formResidenceDuration.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    storeDealFormFields(formConstructorPersonalInfo);
    storeDealFormFields(sortedAddressInfoFields);
    storeDealFormFields(sortedPreviousAddressInfoFields);
    storeDealFormFields(sortedResidentDurationFields);
    setPersonalInfo(formConstructorPersonalInfo);
    setAddressInfo(sortedAddressInfoFields);
    setPreviousAddressInfo(sortedPreviousAddressInfoFields);
    setResidentDuration(sortedResidentDurationFields);
    storeFieldsKeys(fields);
    updateLoading(false);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: undefined });
    setFormData({ ...formData, [name]: value });
  };
  const onSubmit = async () => {
    setFormErrors({});
    updateLoading(true);
    let fields = createCompleteFieldsList();
    let formError = {};
    fields.forEach((field) => {
      if (validationExcludeList.includes(field.name)) {
        return;
      }
      if (!previousVisible && addressPreviousInfo.includes(field.name)) {
        return;
      }
      if (
        formData[field.key] === null ||
        formData[field.key] === undefined ||
        formData[field.key] === ""
      ) {
        formError[field.key] = true;
      }
    });
    console.log(formError);
    if (Object.keys(formError).length > 0) {
      setFormErrors(formError);
      updateLoading(false);
      return;
    }
    let dealId = readDealId();
    console.log(dealId);
    console.log(formData);
    try {
      let response = await savePersonalInfo(formData, dealId);
      updateLoading(false);
      navigate("/incomeAsset");
      //showToast("Saved personal information.");
    } catch (error) {
      console.log("Error from log", error.response);
      const { status, data } = error;
      openAlert(
        "Can you please check the information you have provided something isn’t right.",
        "Error in fetching credit score."
      );

      updateLoading(false);
    }
    // let creditFields = getApiKeysOfCreditCheckFields(fields);
    // let dataForCreditCheck = {};
    // creditFields.forEach((field) => {
    //   dataForCreditCheck = {
    //     ...dataForCreditCheck,
    //     [field.name]: formData[field.key],
    //   };
    // });
    // fields = fetchDealFieldsFromApp();
    // let apiKeyForCreditScore = getApiKeyOfCreditScore(fields);
    // let dataForPipeDriveUpdate = {
    //   ...formData,
    //   title: `${dataForCreditCheck["First_Name"]}'s Deal`,
    // };
    // updateLoading(true);
    // let score;
    // try {
    //   score = await fetchScoreFromApi(dataForCreditCheck);
    // } catch (error) {
    //   updateLoading(false);
    //   openAlert("Information provided not found.", "Credit Score error.");
    //   return;
    // }

    // await updateADeal({
    //   ...dataForPipeDriveUpdate,
    //   [apiKeyForCreditScore]: score.toString(),
    // });
    // updateLoading(false);
  };
  const onChangeSuggestion = async (e) => {
    const { value } = e.target;
    try {
      let places = await placeLookup(value);
      console.log(places);
      setSuggestionList(places);
    } catch (error) {
      const { canSend } = error;
      if (canSend) {
        onChangeSuggestion(e);
      }
    }
  };
  const onChangeSuggestionPrevious = async (e) => {
    const { value } = e.target;
    try {
      let places = await placeLookup(value);
      console.log(places);
      setSuggestionListPrevious(places);
    } catch (error) {
      const { canSend } = error;
      if (canSend) {
        onChangeSuggestionPrevious(e);
      }
    }
  };
  const onSelect = (suggestion) => {
    console.log(suggestion);
    const { street_name, city, postal_code } = suggestion;
    let apiKeyStreetName = extractAPIKey("Street_Name");
    let apiKeyCity = extractAPIKey("City");
    let apiKeyPostalCode = extractAPIKey("Postal_Code");
    let preparedCityForm = {
      [apiKeyStreetName]: street_name,
      [apiKeyPostalCode]: postal_code,
      [apiKeyCity]: city,
    };
    setFormData({ ...formData, ...preparedCityForm });
    setSelectedSuggestion(suggestion);
    setSuggestionList([]);
    console.log(preparedCityForm);
  };
  const onSelectPrevious = (suggestion) => {
    console.log(suggestion);
    const { street_name, city, postal_code } = suggestion;
    let apiKeyStreetName = extractAPIKey("Previous_Street_Name");
    let apiKeyCity = extractAPIKey("Previous_City");
    let apiKeyPostalCode = extractAPIKey("Previous_Postal_Code");
    let preparedCityForm = {
      [apiKeyStreetName]: street_name,
      [apiKeyPostalCode]: postal_code,
      [apiKeyCity]: city,
    };
    setFormData({ ...formData, ...preparedCityForm });
    setPreviousSelectedSuggestion(suggestion);
    console.log(preparedCityForm);
  };
  const onChangeRadio = (key, value, name) => {
    storePRStatus(value === "Yes" ? "true" : "false");
    setFormData({ ...formData, [key]: value });
  };

  return (
    <>
      <div className="main-wrapper" style={{marginLeft: "8vw",maxWidth: "85vw"}}>
        <form autoComplete="off">
          <div className="personal-info-wrapper" >
            <div className="personal_info_title">PERSONAL INFORMATION</div>
            <div className="field-wrapper">
              {personalInformation.map((_field) => {
                return (
                  <div className="input-wrap" style={{ position: "relative" }}>
                    <div>{_field.labelText}</div>
                    {_field.type === "dropdown" ? (
                      <select
                        name={_field.name}
                        onChange={onInputChange}
                        className={formErrors[_field.name] ? `error` : ``}
                      >
                        {renderOptions(_field).map((optionValue) => {
                          return (
                            <option value={optionValue.value}>
                              {optionValue.label}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <>
                        <input
                          autoComplete="off"
                          key={_field.sortOrder}
                          type={_field.type}
                          placeholder={_field.hintText}
                          name={_field.name}
                          className={formErrors[_field.name] ? `error` : ``}
                          onChange={onInputChange}
                          style={
                            _field.name == extractAPIKey("First_Name")
                              ? { paddingLeft: "80px" }
                              : {}
                          }
                        />
                        {_field.name == extractAPIKey("First_Name") ? (
                          <select
                            style={{
                              position: "absolute",
                              top: "18px",
                              maxWidth: "80px",
                            }}
                            name={extractAPIKey("Title")}
                            onChange={onInputChange}
                          >
                            <option>Title</option>
                            <option value="Miss">Miss</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Defacto">Defacto</option>
                          </select>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="address__wrapper">
            <div className="title-wrapper">
              <div className="personal_info_title">ADDRESS INFORMATON</div>
            </div>
            <div className="field-wrapper">
              {/*addressInformation.map((_field) => {
                return (
                  <>
                    <div className="input-wrap">
                      <div>{_field.labelText}</div>
                      <input
                        autoComplete="off"
                        key={_field.sortOrder}
                        type="text"
                        placeholder={_field.hintText}
                        name={_field.name}
                        className={formErrors[_field.name] ? `error` : ``}
                        onChange={onInputChange}
                      />
                    </div>
                  </>
                );
              })*/}
              <div className="input-wrap ">
                <div>Address Lookup</div>
                <AutoCompleteInput
                  className="address_placeholder"
                  suggestions={suggestionList}
                  value={selectedSuggestion}
                  onChange={onChangeSuggestion}
                  onSelect={onSelect}
                />
              </div>
              <div className="input-wrap">
                <div>Time at Current Address</div>
                <div className="input-wrap-line">
                  {residentDuration.map((_field) => {
                    return (
                      <div className="dropdown_container">
                        {_field.type === "dropdown" ? (
                          <select
                            name={_field.name}
                            onChange={onInputChange}
                            className={formErrors[_field.name] ? `error` : ``}
                          >
                            {renderOptions(_field).map((optionValue) => {
                              return (
                                <option value={optionValue.value}>
                                  {optionValue.label}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <input
                            autoComplete="off"
                            key={_field.sortOrder}
                            type="text"
                            placeholder={_field.hintText}
                            name={_field.name}
                            className={formErrors[_field.name] ? `error` : ``}
                            onChange={onInputChange}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="nzpr__fields__wrap">
            {dealFields.map((entry) => {
              return nzResident.includes(entry.name) ? (
                <div className="expense__input__container radio__wrapper">
                  <div className="radio_label" style={{color: "#FFF"}}>
                    Are you a New Zealand Permanent resident
                  </div>
                  <div className="radio_select">
                    <div className="flex-row reduce_expense_radio">
                      <input
                        id="pays-radio"
                        className="checkbox-custom"
                        type={"radio"}
                        value="Yes"
                        onClick={() => {
                          localStorage.setItem("PERMANANT_RESIDENT","true");
                          
                          onChangeRadio(entry.key, "Yes", entry.name);
                        }}
                        name={entry.key}
                      />
                      <label
                        for="pays-radio"
                        className="range__label checkbox-custom-label"
                      >
                        <div style={{color: "#FFF"}}>Yes</div>
                      </label>
                    </div>
                    <div className="flex-row reduce_expense_radio">
                      <input
                        id="pays-radio-2"
                        type={"radio"}
                        value="No"
                        className="checkbox-custom"
                        onClick={() => {
                          localStorage.setItem("PERMANANT_RESIDENT","false");
                          onChangeRadio(entry.key, "No", entry.name);
                        }}
                        name={entry.key}
                      />
                      <label
                        for="pays-radio-2"
                        className="range__label checkbox-custom-label"
                      >
                        <div style={{color: "#FFF"}}>No</div>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              );
            })}
          </div>
          <div
            className={`personal-info-wrapper ${previousVisible ? `` : `hide`}`}
            style={{width: "100%",minWidth: "600px"}}
          >
            <div className="title-wrapper">
              <div className="personal_info_title">
                PREVIOUS ADDRESS INFORMATON
              </div>
            </div>
            <div className="field-wrapper" style={{paddingBottom: "1rem",width: "100%"}}> 
            <AutoCompleteInput
                  className="address_placeholder"
                  suggestions={suggestionListPrevious}
                  value={previousSelectedSuggestion}
                  onChange={onChangeSuggestionPrevious}
                  onSelect={onSelectPrevious}
                />
            </div>
          </div>
        </form>
      </div>
      <ButtonPanel
        isPreviousEnabled={false}
        forwardMethod={async () => {
          console.log(`Clicked`);
          await onSubmit();
        }}
      />
    </>
  );
};
export default PersonalInformation;
