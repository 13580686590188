export const localStorageReadJsonData = (key)=>{
    let encodedData = localStorage.getItem(key);
    return encodedData!==null? JSON.parse(encodedData):null;
}; 

export const localStorageWriteJsonData = (key,payload)=>{
    let dataToWrite = {};
    let currentDataInKey = localStorageReadJsonData(key);
    if(currentDataInKey!=undefined&&currentDataInKey!==null&&currentDataInKey!=''){
       if(currentDataInKey!==payload)
       {
        dataToWrite = {...currentDataInKey};
       }
    }
    dataToWrite = {...dataToWrite,...payload};
    let encodedData = JSON.stringify(dataToWrite);
    localStorage.setItem(key,encodedData);

};
export const localStorageWriteData = (key,data)=>{
    localStorage.setItem(key,data);
};
export const localStorageReadData = (key)=>{
    return localStorage.getItem(key);
};
export const localStorageRemoveData = (key)=>{
    localStorage.removeItem(key);
};