export const expenceFields = [
    "Joint_Applicant_House_Expense",
    "Joint_Applicant_Insurance_Expense",
    "Joint_Applicant_Utilities_Expense",
    "Joint_Applicant_Other_Expense",
    "Joint_Applicant_Personal_Expense",
    "Joint_Applicant_Living_Expense",
  ];
  export  const creditCardsNoField = ["Joint_Applicant_No_Of_Credit_Cards"];
  export  const mortageFields = ["Joint_Applicant_Mortgage_Provider", "Joint_Applicant_Mortgage_Balance","Joint_Applicant_Mortgage_Repayment_Amount"];
  export  const buyNowPayLater = ["Joint_Applicant_Have_Buy_Now_Pay_Later"];
  export const buyNowPayLaterFields = [
    "Joint_Applicant_Buy_Now_Pay_Later_Provider",
    "Joint_Applicant_Buy_Now_Pay_Later_Owing",
  ];
  export const personalLoan = ["Joint_Applicant_Have_Personal_Loan"];
  export const personalLoanFields = [
    "Joint_Applicant_Personal_Loan_Provider",
    "Joint_Applicant_Personal_Loan_Balance",
    "Joint_Applicant_Personal_Loan_Repayment_Amount",
  ];
  export const carLoan = ["Joint_Applicant_Have_Car_Loan"];
  export const carLoanFields = [
    "Joint_Applicant_Car_Loan_Provider",
    "Joint_Applicant_Car_Loan_Balance",
    "Joint_Applicant_Car_Loan_Repayment_Amount",
  ];
  