export const incomeFields = [
  "Take_Home_Income_Per_Month",
  "Partner_Income_Per_Month",
  "Other_Income_Per_Month",
];
export const expenceFields = [
  "Rates_Expense",
  "Insurance_Expense",
  "Utilities_Expense",
  "Dependents_Expense",
  "Mortgage_Or_Rent_Expense",
  "Living_Expense",
];
export const valueFields = [
  "Value_Home",
  "Value_Property",
  "Value_Vehicle",
  "Value_Kiwisaver/Share",
  "Value_Content",
];
export const liabilityFields = [
  "Mortgage_Or_Rent_Liabilities",
  "Loans_Liabilities",
  "Credit_Cards_Liabilities",
];
export const employementFields = [
  "Current_Employer",
  "Current_Job_Description",
  "Current_Employment_Status",
  "Time_With_Employer",
  "Current_Employment_Industry"
];
export const previousEmployementFields = [
  "Previous_Employer",
  "Previous_Job_Description",
  "Previous_Employment_Status",
  "Time_With_Employer",
  "Previous_Employment_Industry"
];
export const timeWithCurrentEmployer = [
  "Time_With_Employer_Years",
  "Time_With_Employer_Months",
];
export const timeWithPreviousEmployer = [
  "Time_With_Previous_Employer_Years",
  "Time_With_Previous_Employer_Months",
];
export const dropdownList = [
  "Current_Employment_Status",
  "Previous_Employment_Status",
  "Current_Employment_Industry",
  "Previous_Employment_Industry"
];
export const payDecreased = ["Pay_Decreased_In_One_Year"];
export const selfEmployed = ["Self_Employed"];
