import React from "react";
import "./footer.css";
import FooterLogo from "../../assets/images/powerd.png";
const Footer = () => {
  return (
    <div className="rootFooter greyBackgroundFooter">
      <div className="footerContent">
        <div className="footerPadding" style={{color: "black"}}>Powered by</div>
        <img className="logo" src={FooterLogo} />
        <span>APEX</span>
      </div>
    </div>
  );
};
export default Footer;
