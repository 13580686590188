import React from "react";
import './others.css';
const ButtonPanel = (props)=>{
    const {isNextEnabled=true,isPreviousEnabled=true,forwardMethod,backwardMethod} = props;
    return (
        <div className="button-panel" style={{display:"flex",justifyContent: "space-between",marginTop: "1rem"}}>
            {isPreviousEnabled?<button onClick={backwardMethod} className="button flat"><div className="red-button button-panel-inner"><strong>&#8592;</strong>&nbsp;Previous</div></button>:<div button-panel-inner>&nbsp;</div>}
            {isNextEnabled?<button onClick={forwardMethod} className="button  primaryBackground" style={{display: "flex",justifySelf: "flex-end"}}><div className="red-button button-panel-inner">Next&nbsp;<strong>&#8594;</strong></div></button>:null}
        </div>
    );
};
export default ButtonPanel;