export const validationExcludeList = ["Mid_Name"];
  export const personalInfo = [
    "First_Name",
    "Mid_Name",
    "Last_Name",
    "Gender",
    "Mobile",
    "Email",
    "No_Of_Depenants",
    "Country_Of_Citizenship",
    "Country_Of_Birth",
    "Marital_Status",
    "Driver_Licence_Type",
    "Driver_Licence_Number",
    "Driver_Licence_Vesrion",
    "Resident_Type",
  ];
  export const datePicker = ["DOB","Licence_Expiry"];
  export const addressInfo = ["City", "Postal_Code", "Street_Name"];
  export const addressPreviousInfo = [
    "Previous_City",
    "Previous_Postal_Code",
    "Previous_Street_Name",
  ];
  export const dropdowns = [
    "No_Of_Depenants",
    "Country_Of_Citizenship",
    "Country_Of_Birth",
    "Driver_Licence_Type",
    "Gender",
    "Marital_Status",
    "Resident_Years",
    "Resident_Months",
    "Resident_Type",
  ];
  export const creditCheckFields = [
    "Gender",
    "DOB",
    "First_Name",
    "Last_Name",
    "City",
    "Street_Name",
    "Postal_Code",
  ];
  export const residentTime = ["Resident_Years", "Resident_Months"];
  export const nzResident = ["New_Zealand_Permanent_Resident"];