import { localStorageReadData, localStorageReadJsonData, localStorageRemoveData, localStorageWriteData, localStorageWriteJsonData } from "./local_storage";
import {downloadPipedriveFields} from './api';
import { IS_MARRIED, IS_MORTAGED, IS_PERMENANT_RESIDENT, IS_RENTING } from "../common/const/local";

export const pipedriveKey = (dealFields,name)=>{
    let apiKey;
    dealFields.forEach(entry=> {
        if(entry.name===name)
        {
            apiKey = entry.key;
        }
    });
    return apiKey;
};
export const saveDealFieldsToApp = (dealFields)=>{
    localStorageWriteJsonData('DEAL_FIELDS',dealFields);
}
export const storeDealFormFields = (formDealFields)=>{
    // let jsonFromLocal = localStorageReadJsonData('FORM_DEAL_FIELDS');
    // const convertedArray = []
    // if(jsonFromLocal!==null){
    //     Object.keys(jsonFromLocal).forEach(key => convertedArray.push(jsonFromLocal[key]));
    // }
    // formDealFields.forEach((incomingField)=>{
    //     if(!convertedArray.includes(incomingField)){
    //         convertedArray.push(incomingField);
            
    //     }
    // });
    
   localStorageWriteJsonData('FORM_DEAL_FIELDS',formDealFields);
   
   
};
export const fetchDealFormFieldsFromApp = ()=>{
    let jsonFromLocal = localStorageReadJsonData('FORM_DEAL_FIELDS');
    if(jsonFromLocal===null){
        return null;
    }
    const convertedArray = []
    Object.keys(jsonFromLocal).forEach(key => convertedArray.push(jsonFromLocal[key]));
    return convertedArray;
};
export const fetchDealFieldsFromApp = ()=>{
    let jsonFromLocal = localStorageReadJsonData('DEAL_FIELDS');
    if(jsonFromLocal===null){
        return null;
    }
    const convertedArray = []
    Object.keys(jsonFromLocal).forEach(key => convertedArray.push(jsonFromLocal[key]));
    return convertedArray;
};
export const saveFormFields = (fromFields)=>{
    localStorageWriteJsonData('FORM_DIELDS',fromFields);
};
export const readFromFields = ()=>{
    return localStorageReadJsonData('FORM_FIELDS');
};

export const downloadFieldsPipedrive =async ()=>{
    try {
        let _dealFields = await downloadPipedriveFields();
        console.log(`---------- Pipedrive Fields ---------------`);
        console.log(_dealFields)
        saveDealFieldsToApp(_dealFields);
        return _dealFields;
      } catch (error) {
       throw error;
      }
};
export const flushData = ()=>{
    localStorageRemoveData('FORM_FIELDS');
    localStorageRemoveData('DEAL_FIELDS');
};
export const createDeal = (data)=>{
    
};
export const flushLocal = ()=>{
    flushData();
    localStorageRemoveData('DEAL_ID');
    flushLocalResidenceYearsAndFirstName();
};
export const storeResidenceYears = (years)=>{
    localStorageWriteData('RESIDENCE_YEARS',years);
};
export const readResidenceYears = ()=>{
    return localStorageReadData('RESIDENCE_YEARS');
};
export const storeFirstName = (firstName)=>{
    localStorageWriteData('FIRST_NAME',firstName);
};
export const readFirstName = ()=>{
    return localStorageReadData('FIRST_NAME');
};
export const storeDealId = (dealId)=>{
    localStorageWriteData('DEAL_ID',dealId);
};
export const readDealId = ()=>{
    return localStorageReadData('DEAL_ID');
};
export const flushLocalResidenceYearsAndFirstName = ()=>{
    localStorageRemoveData('RESIDENCE_YEARS');
    localStorageRemoveData('FIRST_NAME');
};
export const extractAPIKey = (fieldName)=>{
    if(!fieldName){
        return;
    }
    let form_data = fetchDealFieldsFromApp();
    if(!form_data){
        return;
    }
    let apiStreetName = form_data.filter((field)=>{
      return field.name===fieldName
    });
    if(!apiStreetName[0]){
        console.log(fieldName);
    }
    return apiStreetName[0]?.key||null;
}

export const storeMarriageStatus = (value)=>{
    localStorageWriteData(IS_MARRIED,value);
}
export const readMarriageStatus = ()=>{
    let marriageStatus = localStorageReadData(IS_MARRIED);
    return marriageStatus==='true';
};
export const storeMortageStatus = (value)=>{
    localStorageWriteData(IS_MORTAGED,value);
}
export const storeRentingStatus = (value)=>{
    localStorageWriteData(IS_RENTING,value);
}
export const readMortageStatus = ()=>{
    let mortageStatus = localStorageReadData(IS_MORTAGED);
    return mortageStatus==='true';
};
export const readRentingStatus = ()=>{
    let rentingStatus = localStorageReadData(IS_RENTING);
    return rentingStatus==='true';
};
export const storePRStatus = (value)=>{
    localStorageWriteData(IS_PERMENANT_RESIDENT,value);
}
export const readPRStatus = ()=>{
    let prStatus = localStorageReadData(IS_PERMENANT_RESIDENT);
    return prStatus=='true';
};

