import "./App.css";
import Finance from "./finance";
import AppProvider from "./app_provider";

function App() {
  return (
    <AppProvider>
      <div className="app">
        <Finance />
      </div>
    </AppProvider>
  );
}

export default App;
