import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./suggestion.css";
const SuggestionPane = ({ suggestions, activeSuggestion,onClick }) => {
  return suggestions.length > 0 ? (
    <ul class="suggestions">
      {suggestions.map((suggestion, index) => {
        let className;

        // Flag the active suggestion with a class
        if (index === activeSuggestion) {
          className = "suggestion-active";
        }

        return (
          <li
            className={className}
            key={index}
            onClick={(e) => {
              e.preventDefault();
              onClick(suggestion);
            }}
          >
            {suggestion.formatted_address}
          </li>
        );
      })}
    </ul>
  ) : (
    <div class="no-suggestions">
      <em>No suggestions, you're on your own!</em>
    </div>
  );
};
const AutoCompleteInput = (props) => {
  const { suggestions, onChange, value, onSelect } = props;
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [SuggestionComponent, setSuggestionComponent] = useState();
  useEffect(() => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    } else {
        setShowSuggestions(false);
    }
  }, [suggestions]);
  const onClick = (suggestion) => {
    setActiveSuggestion(0);
    setShowSuggestions(false);
    onSelect(suggestion);
  };
  const onKeyDown = (e) => {
    console.log(e.keyCode);

    // User pressed the enter key
    if (e.keyCode === 13) {
      onSelect(suggestions[activeSuggestion]);
      setActiveSuggestion(0);
      setShowSuggestions(false);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion === suggestions.length -1) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
  };
  return (
    <div className="suggestion__wrapper">
      <input
        type="text"
        onChange={onChange}
        {...props}
        onKeyDown={onKeyDown}
        value={value?.formatted_address}
        placeholder="Address"
      />
      {showSuggestions ? <SuggestionPane suggestions={suggestions} activeSuggestion={activeSuggestion} onClick={onClick}/>: null}
    </div>
  );
};
export default AutoCompleteInput;
