import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../app_provider";
import Card from "../../common/components/other/card";
import { useNavigate } from "react-router-dom";
import "../../common/components/other/spinner.css";
// import { useHistory } from 'react-router';
import "./thanks.css";
import {
  fetchDealFieldsFromApp,
  flushLocal,
  readDealId,
} from "../../utilities/methods";
import {
  saveAllToLixi,
  saveApplication,
  sendEmail,
  updateADeal,
  updateApplicationStage,
} from "../../utilities/api";
import {
  getJointApplicantInfo,
  isJointApplication,
} from "../../utilities/local";
import { useRef } from "react";
import { useState } from "react";
const Thanks = () => {
  const [hasFetched, setHasFetched] = useState(false);
  const { updateLoading, setProgress, isLoading, progress } =
    useContext(AppContext);
  const mailStatus = useRef(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Invoked", isLoading, progress, hasFetched);
    // if (!hasFetched) {

    //   // Mark that fetchIsCompleted has been called
    // }
    fetchIsCompleted();
  }, []);

  //const history = useHistory();

  // const handleBackButton = () => {
  //   // Define your custom logic for handling the back button
  //   // For example, you can prevent navigation in certain cases:
  //   if (!hasFetched) {
  //     return;
  //   }

  //   //history.goBack(); // Go back using React Router's history object
  // };

  // Attach the custom back button handler
  // window.addEventListener('popstate', handleBackButton);
  const fetchIsCompleted = async () => {
    try {
      // Update loading state and progress

      let dealId = readDealId();
      let invoked = localStorage.getItem("INVOKED");
      if (invoked != "true") {
        console.log("Senting Email");
        localStorage.setItem("INVOKED", true);
        let response = await saveApplication({ dealId });
        console.log(response);
        setHasFetched(true);
        updateLoading(false);
        setProgress(100);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="thanks-root">
        <Card>
          <div className="thanks-title">Thank you for your application</div>
          {!hasFetched ? <div className="loader"></div> : null}
          <div className="thanks-body">
            Please wait while your application submitted.
          </div>
          {hasFetched ? (
            <button
              className={`button primaryBackground home`}
              style={
                hasFetched
                  ? {}
                  : {
                      background: "grey!important",
                      padding: "1rem",
                      margin: "1rem",
                    }
              }
              onClick={
                hasFetched
                  ? () => {
                      localStorage.clear();
                      navigate("/");
                    }
                  : () => {}
              }
            >
              Home
            </button>
          ) : (
            <div style={{ padding: "1rem", margin: "2rem" }}>Saving...</div>
          )}
        </Card>
      </div>
    </>
  );
};
export default Thanks;
