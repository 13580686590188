import React from "react";
import axios from "axios";
import Card from "../../common/components/other/card";
import "./illion.css";
import IllionLogo from "../../common/assets/images/image 30.png";
import { useContext } from "react";
import { AppContext } from "../../app_provider";
import { useEffect } from "react";
import ButtonPanel from "../../common/components/other/button_panel";
import Statement from "../../common/assets/images/statement.png";
import IllionBankStatement from "../../common/assets/images/image 29.png";
import Check from '../../common/assets/images/check.png';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { saveFile, uploadBankStatement } from "../../utilities/api";
import { PipedriveApi, PipedriveAuthToken } from "../../utilities/api";
import { pipedriveKey, readDealId } from "../../utilities/methods";

const IllionJoint = () => {
  const { setProgress, updateLoading, openAlert } = useContext(AppContext);
  const [statement, setStatement] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    setProgress(90);
    updateLoading(false);
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  const openRetrivalDialogue = () => {
    openAlert(
      <div className="retrival-dialogue">
        <iframe src="https://scv.bankstatements.com.au/NDSS" />
      </div>,
      ""
    );
  };
  const uploadFiles = async (file) => {
    let form = new FormData();
    let dealId = readDealId();
    form.append("file", file);
    form.append("deal_id", dealId);
    await upload(form);
  };
  const upload = async (formData) => {
    let response = await axios.post(
      `${PipedriveApi}/files?api_token=${PipedriveAuthToken}`,
      formData
    );
    
  };
  return (
    <>
      <div className="illion-master">
        <div className="root-illion">
          <Card className="illion-card">
            <div className="illion-title">
              Final step is verifying your income and expenses? This is
              completely secure
            </div>
            <div className="income-verification">Income verification</div>
            <img className="illion-logo" src={IllionLogo} />
            <div className="body-text">
              Illion's market-leading Bank Statements technology provides a
              lighting fast, frictionless web-based solution for the retrieval
              of bank statement data, its uses state of the art Data is
              encrypted with bank level 256-bit encryption, secured by 2048-bit
              keys with the product used by most leading banks and finance
              company’s . No passwords or information are saved or shared <br />
              <br /> We have Automated retrieval which you simply login to your
              statements and it selects the relevant information and downloads.{" "}
            </div>
            <div className="tiles">
              <div class="card-black-illion">
                <div className="illion-container">
                  <img src={IllionBankStatement} />
                  <div className="illion-retrival">Automated retrieval</div>
                  <div className="start-button">
                    <button
                      onClick={openRetrivalDialogue}
                      className="button  primaryBackground"
                    >
                      <div className="inner">
                        Start&nbsp;<strong>&#8594;</strong>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="focus">
                <div className="sub-title">Please uploads minimum 90 days of statements from all bank accounts <span style={{color: "#ABABAB", fontSize: "10px"}}>( PDF Only and Max file size 2MB )</span></div>
                <div class="card-black">
                  <div className="container">
                    {statement===undefined?<img src={Statement} />:<img src={Check} />}
                  </div>
                </div>
                <div className="buttons">
                  <label for="fileFour" className="upload">
                    Upload
                  </label>
                  <label className="grey remove" onClick={()=>{
                    setStatement(undefined);
                  }}>Remove</label>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <input
          type="file"
          id="fileFour"
          className="file"
          onChange={(e) => {
            setStatement(e.target.files[0]);
          }}
        />
        <ButtonPanel
          isPreviousEnabled={false}
          forwardMethod={async () => {
            const fileSize =statement? (statement.size/(1024 * 1024)):0;
          
          if(fileSize>1.0){
            console.log(fileSize);
            openAlert("The file size must be less than 2 MB","Unsupported File Size");
            return;
          }
          
       
          if(statement!==undefined&&statement!==null){
            updateLoading(true);
            let form = new FormData();
            form.append("name", "Bank_Statement_Joint");
            form.append("key", "Bank_Statement_Joint");
            form.append("file", statement);
            console.log(form);
            let response = await saveFile(form);
            console.log(response);
            await uploadBankStatement(statement);
            await uploadFiles(statement);
            updateLoading(false);
          }

            navigate("/thanks");
          }}
        />
      </div>
    </>
  );
};
export default IllionJoint;
