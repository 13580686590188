import React,{useContext} from 'react';
import ProgressBar from '../other/progress_bar';
import './header.css';
import Logo from '../../assets/images/logo.png';
import { AppContext } from '../../../app_provider';
import {Link} from 'react-router-dom'
const Header = (props)=>{
    const {progress} = useContext(AppContext); 
return(
    <div className='root'>
        <div className='logo'><a href="/"><img src={Logo} alt="App Logo" className='logo-image'/></a></div>
        <div className='primaryColor announceText'>This will only take a minute</div>
        <ProgressBar completed = {progress} />
    </div>
);
};
export default Header;